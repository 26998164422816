@use 'sass:color';
@import 'scss/env.scss';

.main {
  position: relative;
  padding: 0 !important;

  > div {
    &:last-child {
      @include flex($justify: center);
      position: absolute;
      transform: translateY(-100%);
      width: 100%;
      transition: 0.3s opacity;

      > div {
        &:first-child {
          transform: translateY(calc(-100% + 2px)) rotate(45deg);
          position: absolute;
          width: 20px;
          border: 1px solid color.adjust($color: $theme-color-primary, $lightness: -30%);
          height: 20px;
        }
        &:last-child {
          position: absolute;
          background-color: #fff;
          border: 2px solid black;
          box-sizing: border-box;
          color: black;
          padding: 5px;
        }
      }
    }
  }
}
