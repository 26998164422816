.check.check {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.main {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  width: 80%;
  height: 80%;

  .pageTitle {
    margin: 10px 0;
    text-align: center;
  }

  .data {
    display: flex;
    flex-grow: 1;
    height: inherit;

    .filters {
      display: flex;
      flex-direction: column;
      min-width: 250px;
      max-width: 250px;

      .all {
        padding: 10px;
        overflow: auto;
        flex-grow: 1;
      }

      .title {
        display: flex;
        align-items: center;
        margin: 10px 0;

        > div {
          &:first-child {
            left: 0;
            background-color: #00d8f4;
            width: 15px;
            padding: 25px 0;
            align-self: stretch;
            $radius: 15px;
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
            box-shadow: 0 0 8px 2px #00d8f4;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    .content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding: 10px;
      min-width: 0;
      min-height: 0;

      .books {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
        overflow: auto;
        align-content: flex-start;

        .card {
          $imgSize: 100px;

          border-radius: 5px;
          display: flex;
          flex-direction: column;
          margin: 5px;
          width: fit-content;
          transition: 0.3s;
          cursor: pointer;

          > div {
            margin: 5px;
          }

          > * {
            width: $imgSize * 2;
          }

          .images {
            display: flex;
            flex-wrap: wrap;
            background-color: #eee;
            border-radius: 5px;
            height: $imgSize * 2;
            overflow: hidden;

            * {
              width: $imgSize;
              height: $imgSize;
            }

            .no {
              display: flex;
              align-self: center;
              justify-content: center;
              flex-grow: 1;
              color: #aaa;
            }
          }

          .name {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            > div {
              overflow: hidden;
              flex-grow: 1;
            }
          }

          &:hover {
            background-color: #eee;
          }
        }
      }

      .apply {
        display: flex;
        justify-content: flex-end;

        * {
          text-transform: uppercase;
          margin: 5px;
          background-color: #eee;

          &:hover {
            background-color: black;
          }
        }
      }
    }
  }
}

.dropdown {
  display: flex;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;

  > div {
    &:first-child {
      flex-grow: 1;
    }

    &:last-child {
      font-size: 1.8em;
    }
  }

  &Content {
    padding-left: 10px;
  }
}

.date {
  display: flex;
  flex-direction: column;

  > div {
    flex-grow: 1;
  }

  *:last-child {
    align-self: flex-end;
    width: 100px;
  }
}
