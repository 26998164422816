@import 'scss/_env.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patterns {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  z-index: 2;
}
