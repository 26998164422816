.main.main.main {
  display: flex;
  align-items: center;
  margin: 8px;

  > a,
  > div {
    margin: 5px;
    box-shadow: none;
    font-style: italic;
    flex-grow: unset;
  }
  > div {
    display: flex;
    justify-content: center;
    cursor: default;
    padding: unset;
  }
}
