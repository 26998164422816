@import 'scss/_env.scss';

.main {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  width: 60%;

  > div {
    font-family: Montserrat;
    font-weight: bold;
    margin: 5px;
    text-align: center;
  }

  .slider {
    position: relative;
    content: '';
    display: block;
    width: 100%;
    height: 30px !important;
    border-radius: 30px;
    box-shadow: 0 0 5px $theme-color-primary-overlay;
    overflow: hidden;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      transition: width 0.1s ease-out;
      &:first-child {
        width: 100%;
        background-color: $theme-color-box;
        text-align: center;
      }
      &:last-child {
        background-color: $theme-color;
      }
    }
  }
}
