@import 'scss/env.scss';
@import 'scss/components/_button.module.scss';

.main {
  margin: $normal-margin 0 0 0;
  background-color: transparent;
  padding: 0;
  position: relative;
  font-size: $font-size-small;
  cursor: pointer;

  > div:first-child {
    padding: $normal-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;

    * {
      z-index: 1;
    }

    > h3 {
      font-family: Montserrat;
      color: $color-inverse;
    }

    > div {
      display: flex;

      > form {
        padding: 0;
        box-shadow: none;
        width: auto;
        min-width: auto;
        background-color: transparent;
      }

      .inactive {
        background-color: $theme-color-invalid;
        color: $color-inverse;
      }

      button {
        font-size: $font-size-small;
        margin: $normal-margin;

        &:hover {
          filter: brightness(80%);
        }
      }
    }
  }

  .active {
    background-color: $theme-color-primary;
  }

  .inactive {
    background-color: $theme-color-tertiary;
  }

  > div:last-child {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $default-border-size;
    transition: $default-transition-time;

    &:hover {
      filter: brightness(80%);
    }
  }

  .popup {
    > h2 {
      text-align: center;
    }

    > div {
      > label {
        text-transform: uppercase;
      }
    }
  }
}

.companyBox {
  padding: $big-padding;
  border-left: $default-border-size solid $theme-color-box;
  border-radius: $default-border-radius;
  box-sizing: border-box;

  .companyUsers {
    padding: 5px;
    box-sizing: border-box;

    &Token {
      background-color: $theme-color-background;
      border-radius: 5px;
      padding: 5px;
      margin: $small-margin;
      display: flex;
      flex-wrap: wrap;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &:first-child {
          > p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 5px;
            word-break: break-word;

            &:first-child {
              font-size: 0.7em;
            }
          }
        }
        &:last-child {
          cursor: pointer;
          background-color: $theme-color-inside;
          border-radius: 5px;
          width: 40px;
          svg {
            padding: 5px;
            display: block;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: 5px;

      > p:first-child {
        flex-grow: 1;
      }

      > button,
      > p {
        margin: $small-margin;
      }
    }

    &Delete {
      @extend .button-invalid;
    }
  }
  .companyInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      min-width: 300px;

      > h3 {
        text-transform: uppercase;
      }

      > label,
      p {
        margin: $normal-padding;
        box-sizing: border-box;
      }

      > div {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
