@import 'scss/_env.scss';

.pattern {
  position: relative;
  user-select: none;
  box-shadow: none;
  cursor: pointer;
  min-width: fit-content;

  > div {
    aspect-ratio: 1/1;

    > div {
      position: absolute;
      width: 100%;
      height: auto;
      transition: 0.3s;
      border-radius: 10px;
      pointer-events: none;

      > div {
        overflow: hidden;
        background-color: $theme-color-background;
        transition: 0.3s;
        border-radius: 5px;

        &:first-child {
          aspect-ratio: 1/1;
          overflow: hidden;
          margin: 5px;

          img {
            object-fit: contain;
            border-radius: 5px;
          }
        }
      }
    }

    .hover {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 0;
      transition: 0.3s;
      cursor: default;
      padding: 5px 10px 10px 10px;

      * {
        font-family: Arial;
      }

      button {
        font-size: 0.8vw;
        border: 2px solid #000;
        width: 100%;
        transition: none;
        background-color: #0001;
        border-radius: 5px;
        color: black;
        transition: 0.3s;
        &:hover {
          background-color: #0002;
        }
      }

      &Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        user-select: all;

        .name {
          overflow: hidden;
        }

        .heart {
          padding: 3px;
          cursor: pointer;
          transition: 0.2s;
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          user-select: none;
          color: white;
          filter: drop-shadow(0 0 1px black);

          svg {
            width: 100%;
            height: 100%;
          }

          &:hover {
            color: black;
            filter: none;
          }
        }
      }

      .button {
        margin-top: 5px;
      }
    }

    &:hover {
      filter: none;

      > div {
        box-shadow: 1px 0 5px 2px #0004;
        pointer-events: all;
        > div {
          opacity: 1;

          &:first-child {
            img {
              object-fit: cover;
            }
          }

          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }
}

.placeholder.placeholder {
  display: block;
  width: 100%;
  height: 100%;
}
