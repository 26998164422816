@import 'scss/_env.scss';

.main {
  display: flex;
  padding: 10px 2% !important;
  flex-grow: 1;
  justify-content: space-between;

  * {
    font-family: Montserrat;
    font-weight: bold;
  }

  .book,
  .data {
    display: flex;
    flex-direction: column;
    margin: 5px;
    flex-grow: 1;
    max-width: 45%;
  }

  .book {
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;

      > div {
        display: flex;
        justify-content: space-between;
        margin: 5px;
      }
    }

    .inactiveZone {
      position: relative;
      .delete {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        padding: 5px;
        background-color: transparent;
        box-shadow: none;
      }
    }

    .activeZone {
      cursor: pointer;
      &:hover {
        filter: brightness(80%);
      }
    }

    .activeZone,
    .inactiveZone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      flex-grow: 1;
      background-color: $background-color;
      border-radius: 5px;
      height: 100%;
      box-sizing: border-box;
      color: grey;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      border: 2px dashed black;
      height: 300px;

      .innerImage {
        height: 100%;
      }

      .icon {
        flex-grow: 2;
        width: 100%;
        color: $theme-color-primary-overlay;
      }

      .reject {
        background-color: red;
      }

      .accept {
        background-color: green;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    margin: 20px 0;
    font-family: Montserrat;
    font-weight: normal;
  }

  // &Header {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   background-color: $background-color;
  //   border-radius: 5px;

  //   > div {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;

  //     &:last-child {
  //       flex-grow: 1;
  //     }

  //     > * {
  //       margin: 5px;
  //     }

  //     > div {
  //       display: flex;
  //       padding: 5px;
  //       background-color: $theme-color-box;
  //       border-radius: 5px;

  //       > * {
  //         &:first-child {
  //           min-width: 200px;
  //           width: 30%;
  //         }
  //         &:last-child {
  //           flex-grow: 3;
  //         }
  //       }
  //     }
  //   }

  //   img {
  //     width: $default-image-size;
  //   }
  // }

  &Form {
    display: flex;
    flex-direction: column;
    margin: 5px 0 0 0;
    color: inherit;
    min-width: auto;
    width: auto;
    background-color: transparent;
    padding: 5px;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 10px;

      * {
        &:not(button) {
          font-size: inherit;
        }
        &:not(svg, textarea) {
          height: auto !important;
        }
      }

      > * {
        &:last-child {
          min-width: 200px;
          width: auto;
        }
      }
    }

    label {
      margin: 5px;
      &:first-child {
        width: 30%;
        min-width: 150px;
      }
    }

    .localisation {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;

      > div {
        margin: 5px 20px;
        display: flex;
        justify-content: space-between;
        padding: 0;
      }
    }

    input {
      color: black;
      flex-grow: 1;
    }

    .submit {
      display: flex;
      justify-content: center;

      > button[type='submit'] {
        background-color: #000;
        color: white;
        border-radius: 20px;
        padding: 10px 20px;
        font-size: 1rem;
        font-family: Montserrat;
        font-weight: normal;
      }
    }

    button {
      box-shadow: none;
    }

    textarea {
      box-sizing: border-box;
      border-radius: 5px;
      color: black;
      flex-grow: 1;
      min-height: 30px;
      resize: none;
      height: auto;
      max-height: 150px;
      background-color: $theme-color-overlay;
      border: none;
      outline: none;
    }

    select {
      padding: 5px;
      border-radius: 5px;
      flex-grow: 1;
    }
  }

  .loader {
    align-items: stretch;
    width: 100%;
    flex-grow: 1;
  }
}
