.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  z-index: 1;

  * {
    font-family: Arial, Helvetica, sans-serif;
  }

  .count {
    font-style: italic;
  }

  .pages {
    display: flex;
    margin: 5px;
    align-items: center;

    .current {
      color: #57c7e4;
    }

    button {
      margin: 5px;
      padding: 0;
      box-shadow: none;
      font-size: 1em;
      background-color: transparent;
      color: black;
      &:hover {
        filter: none;
        text-shadow: 0 0 2px #0009;
      }
    }

    .right,
    .left {
      position: relative;
      top: -2px;
      font-size: 1.5em;
      z-index: 0;
    }
  }
}
