.main {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: 300px;

  * {
    margin: 3px;
  }

  .title {
    font-weight: bold;
  }

  button {
    padding: 5px;
    font-size: 1em;
    justify-content: center;
  }

  .selections {
    background-color: black;
    color: white;
    border-radius: 20px;
  }

  .add {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    > * {
      height: 30px;
      padding: 0 5px;
      margin: 0;
      flex-grow: 1;
    }
  }

  form {
    min-width: auto;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  .list {
    height: 130px;
    overflow: auto;

    .selection {
      display: flex;
      align-items: center;
    }

    input[type='checkbox'] {
      width: 18px;
      height: 18px;
    }

    label {
      margin: 0;
      padding: 0 0 0 5px;
    }
  }
}
