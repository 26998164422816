@import 'scss/env.scss';

.main {
  .counterfeits {
    @include flex($dir: column, $justify: space-evenly);
    width: 80%;
    flex-wrap: wrap;
    border-radius: $default-border-radius;

    .counterfeit {
      @include flex();
      padding: $small-padding;
      margin: $normal-margin;
      border: 1px solid black;
      background-color: $theme-color-box;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;

      > * {
        margin: $small-padding;
      }

      > a {
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: $default-image-size;
          height: $default-image-size;
          object-fit: cover;
        }
      }

      > div {
        display: table;
        flex-grow: 1;
        margin-left: 20px;

        > div {
          display: table-row;
          font-size: 1.5em;
          > p,
          > a {
            display: table-cell;
            vertical-align: middle;
            font-family: Montserrat;
            box-shadow: none;
          }
        }
      }
    }
  }

  > div:last-child {
    @include flex($dir: column, $justify: space-evenly, $align: center);

    > * {
      margin: $normal-margin;
    }
  }

  .placeholder {
    width: 100%;
    height: 250px;
  }
}
