@import 'scss/env.scss';

.main {
  @include flex($justify: center);

  > div:last-child {
    @include flex($dir: column);
    margin: $normal-margin;

    .match {
      @include flex($justify: space-evenly);
      flex-wrap: wrap;
      margin: $normal-margin;
      border-radius: $default-border-radius;

      > div {
        margin: $normal-margin;
        border-radius: 5px;

        * > a {
          position: relative;
          display: block;
          overflow: hidden;
          border: 1px solid black;

          > img,
          > div:first-child {
            width: 300px;
            height: 300px;
            object-fit: cover;
          }

          > div:last-child {
            @include flex($justify: center);
            position: absolute;
            top: 0;
            right: 0;
            transform-origin: 100% 0;
            transform: rotate(45deg) translate(50%);
            padding: 5%;
            box-sizing: border-box;
            width: 100%;
            height: 20%;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            background-color: black;
            text-align: center;
            box-sizing: border-box;
            transition: 0.3s;
            color: white;

            > svg {
              height: 100%;
              flex-grow: 1;
            }
          }

          &:hover {
            > div:last-child {
              height: 30%;
              padding-top: 10%;
            }
          }
        }

        &:last-child {
          @include flex($dir: column, $justify: center);

          > button {
            margin: $normal-margin;
            width: 280px;

            &:first-child {
              @extend .button-valid;
            }

            &:nth-child(2) {
              @extend .button-invalid;
            }

            &:nth-child(3) {
              @extend .button-ignore;
            }

            &:last-child {
              @extend .button-ignore;
            }
          }
        }
      }

      > hr {
        width: 2px;
      }
    }

    .placeholder {
      width: auto;
      height: 300px;
    }
  }
}

.popper {
  background-color: #333 !important;
  color: white !important;
}
