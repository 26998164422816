@import 'scss/_env.scss';

.main {
  position: absolute;
  box-shadow: 0 0 5px rgb(37, 37, 37);
  height: 100%;
  width: 50%;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  will-change: transform;
  @-moz-document url-prefix() {
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  > div {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-image: url('/assets/images/book/paper.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 1%;
    box-sizing: border-box;

    @supports (not (-moz-appearance: none)) {
      transform-style: preserve-3d;
    }

    &:first-child {
      transform: rotateX(0deg) scaleX(-1);
    }
    &:last-child {
      @supports (not (-moz-appearance: none)) {
        backface-visibility: hidden;
      }
    }
    > div {
      transform-style: preserve-3d;
      * {
        font-size: 1vw;
      }
      &:first-child {
        flex-grow: 1;
      }
      &:last-child {
        position: relative;
        height: 5%;
        user-select: none;
        > div {
          transform-style: preserve-3d;

          &:first-child {
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 5px;
          }
        }

        > .arrow {
          position: absolute;
          height: 100%;
          padding: 0 5%;
          box-sizing: border-box;
          width: 20%;

          > svg {
            pointer-events: all;
            opacity: 0.5;
            cursor: pointer;
            transition: $default-transition-time;
            transform-style: preserve-3d;
            height: 100%;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
