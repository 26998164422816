@import 'scss/env.scss';

.main {
  position: relative;
  display: flex;
  align-self: stretch;
  justify-self: center;
  box-sizing: border-box;
  transition: $default-transition-time;

  img {
    object-fit: fill;
  }

  &Inside {
    transform-style: preserve-3d;
    background-size: 50% 100%;
    position: relative;
    aspect-ratio: 16/9;
    width: 100%;

    > div {
      display: flex;
      position: relative;
      transform-style: preserve-3d;
      height: 100%;
      width: 100%;
      box-sizing: border-box;

      &:first-child {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        > div {
          height: 100%;
          width: 50%;
          background-size: 100% 100%;
          background-image: url('/assets/images/book/cover.png');

          &:first-child {
            transform: scaleX(-1);
          }
        }
      }

      &:last-child {
        padding: 10px;
        box-sizing: border-box;

        > div {
          &:first-child {
            position: relative;
            height: 100%;
            width: 100%;
            transform-style: preserve-3d;
          }
          &:last-child {
            position: absolute;
            top: 0;
            left: -7%;
            width: 8%;
            height: 100%;
            z-index: -1;
            padding: 20px 0;
            box-sizing: border-box;
          }
        }
      }
    }

    &Config {
      position: relative;
      transform-style: flat;
      > div {
        position: absolute;
        width: 70%;
        height: 90%;
        top: 0;
        left: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(1deg);
        cursor: pointer;

        > * {
          width: 60%;
          height: 50%;
          filter: brightness(100%) drop-shadow(0 0 0px #0000);
          transition: $default-transition-time;

          &:hover {
            filter: brightness(80%) drop-shadow(0 0 2px black);
          }
        }
      }
    }
  }

  &Page {
    transform-origin: right;
    transform-style: preserve-3d;
    * {
      font-weight: 900 !important;
    }
  }
}
