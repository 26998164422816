.main {
  display: flex;
  flex-grow: 1;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;

  > div {
    margin: 5px;
  }

  .img {
    height: 700px;
    width: 600px;
  }

  .desc {
    width: 500px;

    button {
      margin: 10px;
    }

    > * {
      > * {
        > * {
          width: 100%;
        }
      }
    }

    > div {
      margin: 5px;
    }
  }
}
