.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rebracks {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  z-index: 2;
}
