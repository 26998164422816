.main {
  margin: 0 10px;
  text-align: center;
  background-color: transparent;
  color: black;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #000;

  input {
    position: relative;
    background-color: transparent;
    color: #000;
    &::placeholder {
      font-style: italic;
      color: #000b;
    }
  }

  * {
    height: 25px;
  }
}
