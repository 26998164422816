@import 'scss/env.scss';

.main {
  padding: 0 2.5%;
  flex-grow: 1;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    form {
      width: auto;
      margin-right: 40%;
    }

    .form {
      svg {
        width: auto;
        color: red;
        cursor: pointer;
      }

      > * {
        label {
          color: $color;
          width: auto;
          height: fit-content;
        }
      }

      &Edit {
        background-color: $theme-color-box;
        input[type='checkbox'] {
          opacity: 1 !important;
        }
      }

      &Add {
        flex-direction: row;
        input {
          color: black;
        }
      }

      &Warning {
        background-color: rgb(255, 204, 0);
        color: rgb(112, 90, 0);
        padding: $normal-padding;
        border-radius: $default-border-radius;
        font-family: Montserrat;
        font-weight: bold;
      }

      input[type='checkbox'] {
        min-width: 30px;
        min-height: 30px;
        margin: 5px 10px;
        opacity: 0.3;
      }

      button {
        margin: 10px;
      }
    }
  }
}
