@use 'sass:color';
@import 'scss/env.scss';

.main {
  padding: 0 2.5%;

  form {
    > div {
      * {
        font-size: 1.4rem !important;
      }
    }
  }

  > div {
    &:first-child {
      color: black;
      border-radius: 5px;
      margin-bottom: $big-margin;

      > h1 {
        font-family: Montserrat;
      }
    }
  }

  .configButtons {
    display: flex;
    justify-content: flex-end;

    > button {
      margin: $small-margin;
    }
  }

  .companies {
    margin-bottom: 20px;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  h3 {
    font-family: Montserrat;
    font-weight: normal;
    text-transform: uppercase;
  }

  .placeholder {
    width: 100%;
    margin: $small-margin;
  }
}

.formSubscribtion.formSubscribtion {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 30px;

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 5px;
    margin-left: 5px;
    width: auto;

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin: 5px;
    }

    > * {
      display: flex;
      align-items: center;

      > * {
        margin: 5px 10px;
      }
    }

    > label {
      min-width: 400px;
    }
    > input {
      flex-grow: 1;
      min-width: 50px;
    }
  }
}
