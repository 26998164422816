.main {
  > form {
    flex-direction: column;

    > div > input {
      width: 50%;
      padding: 10px !important;
    }
  }
}
