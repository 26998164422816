@import 'scss/env';

.main {
  padding: 0 2.5%;

  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: $theme-color-overlay;
    border-radius: $default-border-radius;
    padding: 5px;

    > div {
      &:first-child {
        min-width: $default-big-image-size;
        height: $default-big-image-size;
        display: flex;
        justify-content: center;
        margin: 10px;
        overflow: hidden;
        border-radius: 5px;

        img {
          object-fit: cover;
          width: $default-big-image-size;
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px;

        > div {
          display: flex;
          > h3 {
            word-wrap: break-word;
            margin: 5px 0;
          }
        }
      }
    }
  }

  &Data {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    > div {
      display: flex;
      flex-wrap: wrap;
      > * {
        padding: 5px;
        &:first-child {
          width: 10%;
          min-width: 300px;
          text-align: right;
        }
        min-width: 20%;
      }
    }
  }

  .pattern {
    position: relative;
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    img {
      object-fit: contain;
    }

    > div {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: $default-transition-time;
      background-color: white;
      opacity: 0.6;
      * {
        text-align: center;
        width: 100%;
      }
    }
    &:hover {
      > div {
        opacity: 1;
      }
    }
  }
}
