@import 'scss/_env.scss';

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 15%;

  > div {
    display: flex;
    flex-direction: column;
  }

  .left {
    width: 40%;
    max-width: 500px;
    min-width: 350px;

    .pattern {
      position: relative;
      border-radius: 5px;
      aspect-ratio: 1/1;
      max-height: 70vh;
      overflow: hidden;

      > * {
        height: 100%;
      }

      .image {
        background-color: #eee;
      }

      &Hover {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      a {
        background-color: $theme-color-press;
        border: 2px solid black;
        padding: 8px 15px;
        font-family: Arial;
        font-size: 0.9em;
        color: black;
        border-radius: 5px;
      }
    }

    > div {
      margin: 5px;
    }
  }

  .right {
    position: relative;
    width: min-content;

    &Texts {
      flex-grow: 1;

      * {
        font-family: Arial, Helvetica, sans-serif;
      }

      h3 {
        font-size: 1.5em;
        font-weight: normal;
        margin: 0 0 20px 0;
      }

      > div {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
      }

      .rightInfo {
        flex-direction: column;

        button,
        a {
          display: flex;
          align-items: center;
          background-color: unset;
          padding: 5px;
          box-shadow: unset;
          font-size: unset;
          text-decoration: underline;
        }

        label {
          display: flex;
          align-items: center;
        }

        label + div {
          display: flex;
        }

        button {
          color: unset;

          > * {
            margin: 0 5px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }

        > div {
          display: flex;
          flex-direction: column;
          margin: 10px 0;

          > div {
            display: flex;
            justify-content: space-between;
            margin: 5px 0 5px 20px;
            padding: 0;
          }
        }
      }
    }

    &Utils {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 5px;

      > * {
        margin: 5px 10px;
        font-family: Arial, Helvetica, sans-serif;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      button {
        padding: 5px 10px;

        > div {
          display: flex;
        }
      }
    }

    hr {
      height: 2px;
      background-color: black;
      margin: 10px 0;
    }
  }

  > * {
    flex-grow: 1;
    margin: 0 30px;
  }
}

.pageImg.pageImg.pageImg.pageImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  background-color: #eee;
  margin: 0;
}

.similar {
  display: flex;
  align-items: center;

  .arr {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    min-height: 30px;
    min-width: 30px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3em;
      border-radius: 50%;
      background-color: transparent;
      transition: 0.3s;
      min-height: 100%;
      min-width: 100%;
      cursor: pointer;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }

  .patterns {
    display: flex;
    flex-grow: 1;
    padding: 0 5px;
    box-sizing: border-box;
    overflow: hidden;

    > div {
      display: flex;
      transition: 0.3s;
      flex-grow: 1;

      > div {
        min-width: 25%;
        max-width: 25%;
        aspect-ratio: 1/1;
        box-sizing: border-box;
        padding: 5px;

        > div,
        a {
          border-radius: 5px;
          background-color: #eee;
          height: 100%;
          width: 100%;
          box-shadow: none;
        }
      }
    }
  }
}
