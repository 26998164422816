.main {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  min-width: 400px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    margin: 50px;

    img {
      height: 100%;
      width: auto;
    }

    .title {
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      width: 65%;
    }

    .polaroid {
      position: relative;
      margin: 10%;
      box-shadow: 2px 2px 5px 3px #0004;

      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        bottom: -15%;
        left: -25%;
        width: 150%;
        border-radius: 12px;
        z-index: -1;
        height: 60%;
        background-color: #59d5f326;
      }
    }

    .upload {
      button {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 0;

        > * {
          margin: 5px;
        }

        > div {
          font-size: 0.8em;
          padding: 3px;
          color: black;
        }

        &:hover {
          filter: none;
        }
      }
    }
  }
}

.accept {
  position: absolute;
  background-color: #0005;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s;
  padding: 10%;
  box-sizing: border-box;
  pointer-events: none;

  &Trigger {
    opacity: 1;
  }

  > * {
    width: 100%;
    height: 100%;
    color: #ccc;
    filter: drop-shadow(0 0 20px #0005);
  }
}

.distance {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80vw;

  * {
    font-family: Montserrat !important;
    word-break: break-all;
    font-weight: bold;
  }

  > div {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #0002;
    border-radius: 5px;
  }
}
