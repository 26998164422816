@import 'scss/env.scss';
@import 'scss/components/_button.module.scss';

.main {
  flex-grow: 1;

  .bold {
    font-weight: bold;
    font-size: 28px;
  }

  .info {
    padding: 2px 5px;
    display: flex;
    align-items: flex-start;
    background-color: $theme-box-color;
    margin: 0 5% 5px 5%;

    > div {
      box-sizing: border-box;
      padding: 3px;

      &:first-child {
        flex-grow: 1;
        @include flex($justify: space-evenly);
        flex-wrap: wrap;

        .sensibility {
          text-align: center;
          font-size: 1.3em;
          text-transform: uppercase;

          &Slider {
            color: $theme-color-secondary;
          }
        }

        button {
          box-shadow: none;
        }

        > div:last-child {
          @include flex($dir: column, $justify: center, $align: center);

          > div:last-child {
            font-weight: bold;
            font-size: $font-size-normal;
          }
        }

        > * {
          margin: $normal-margin $big-margin;
          width: 300px;
          flex-grow: 1;
        }
      }
    }

    &Keyboard {
      display: flex;
      padding: 5px;
      cursor: pointer;
      border-radius: $default-border-radius;
      transition: $default-transition-time;

      &:hover {
        background-color: $theme-color-tertiary;

        svg {
          color: white;
        }
      }

      svg {
        transition: $default-transition-time;
        color: $theme-color-inside;
        width: 30px;
        height: 30px;
      }
    }
  }

  .placeholder {
    width: auto;
    height: 100%;
  }

  .results {
    background-color: $theme-box-color;
    flex-grow: 1;
    margin: 0 3%;

    > div {
      @include flex($justify: space-around);
      flex-wrap: wrap;
      margin: $normal-margin;
      padding: $small-padding;

      &:last-child {
        background-color: $theme-color-overlay;
      }

      > div:last-child {
        @include flex($dir: column, $justify: center, $align: stretch);

        > button {
          margin: $normal-margin;
          cursor: pointer;
        }
      }
    }

    &Header {
      > div {
        &:first-child {
          @include flex($justify: space-around, $wrap: wrap);
          flex-grow: 1;
          > div {
            position: relative;
            margin: $normal-margin;
            padding: $small-padding;
          }
          * > a {
            position: relative;
            height: 300px;
            width: 300px;
            box-shadow: none;
            background-color: $theme-color-overlay;
            border: 1px solid rgba(black, $theme-color-transparency);
            box-shadow: 0 0 2px rgba(black, $theme-color-transparency);
            overflow: hidden;

            &:hover {
              border: 1px solid black;

              > div:not(:first-child) {
                height: 30%;
                padding-top: 10%;
              }
            }

            > div:not(:first-child) {
              @include flex($justify: center);
              position: absolute;
              top: 0;
              right: 0;
              transform-origin: 100% 0;
              transform: rotate(45deg) translate(50%);
              width: 100%;
              height: 20%;
              font-weight: bold;
              padding: 5%;
              box-sizing: border-box;
              background-color: black;
              transition: 0.3s;
              color: white;

              > svg {
                height: 100%;
                flex-grow: 1;
              }
            }

            img {
              object-fit: cover;
            }
          }
        }
      }
    }

    &Content {
      > div {
        position: relative;
        @include flex();
        width: 250px;
        height: 250px;
        margin: $normal-margin;

        > label {
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: $default-transition-time;
          padding: 0;
          box-sizing: border-box;
          box-shadow: 0 0 2px rgba($theme-color, $theme-color-transparency);
          background-color: black;

          &:hover {
            filter: $theme-hover-filter;
          }

          img {
            object-fit: cover;
          }
        }

        > input {
          display: none;

          &:checked + label {
            padding: 3px;
          }
        }
      }

      .imageZoom {
        cursor: pointer;

        > div {
          border-radius: 5px;
        }
      }
    }
  }

  .buttons {
    @include flex($align: stretch);

    > button {
      color: white;
      width: 280px;

      &:first-child {
        @extend .button-valid;
      }

      &:nth-child(2) {
        @extend .button-invalid;
      }

      &:nth-child(3) {
        @extend .button-ignore;
      }

      &:last-child {
        @extend .button-ignore;
      }
    }
  }

  hr {
    width: 2px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;

    > div {
      display: flex;
      justify-content: center;
      margin: 5px;
      width: 100%;

      button {
        margin: 5px;

        &:first-child {
          @extend .button-valid;
        }
        &:last-child {
          @extend .button-invalid;
        }
      }
    }
  }

  .deleted {
    flex-direction: column;
    font-family: Montserrat;
    align-items: center;
    font-weight: bold;
  }

  .bottom {
    display: flex;

    > div {
      flex-grow: 1;
    }

    .sites {
      display: flex;
      flex-direction: column;
      min-width: 240px;
      max-width: 240px;
      overflow: hidden;

      > div {
        display: flex;
        align-items: center;

        input {
          min-width: 20px;
          min-height: 20px;
          margin-left: 20px;
        }

        > div {
          font-family: Arial;
          line-break: loose;
          text-transform: uppercase;
          margin: 10px;
        }
      }

      .title {
        position: relative;
        font-size: 1.4em;
        text-transform: uppercase;
        color: $theme-color-secondary;
        font-weight: bold;
        $offset: 30px;
        margin: 10px 0 10px $offset;
        height: 40px;

        &::before {
          content: '';
          left: -$offset;
          position: absolute;

          border-right: 10px solid $theme-color-secondary;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: 0 0 10px $theme-color-secondary;
          height: 100%;
        }
      }
    }
  }
}

.popper {
  background-color: #333 !important;
  color: white !important;
}
