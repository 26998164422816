@use 'sass:list';
@import 'scss/env.scss';

$themeColors: #ffd70066, #ffd70099, #ffd700, #59d5f3;

.main {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;

  * {
    font-family: Montserrat;
    text-align: center;
    margin: 10px 0;
  }

  .offer {
    h1 {
      font-size: 1.8em;
    }

    h2 {
      font-size: 1.4em;
    }
  }

  > .solutions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5px 10px;
      background: #fff;
      box-shadow: 3px 5px 19px 4px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 15px;
      width: 250px;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          border-top: 8px solid nth($themeColors, $i);
        }
      }

      .catalog {
        display: flex;
        flex-direction: column;

        font-size: 2rem;
        font-weight: bold;

        > p {
          margin: 0;
          text-transform: uppercase;

          &:first-child {
            font-family: Philosopher;

            span {
              color: #00d8f4;
              font-family: Philosopher;
            }
          }

          &:last-child {
            font-family: Montserrat;
            font-weight: normal;
            font-size: 1.9rem;
            letter-spacing: -1px;
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.1em;
        min-height: 30px;

        & + * {
          font-size: 0.9em;
          line-height: 2;
          max-height: 110px;
          overflow: hidden;
        }
      }

      .desc {
        display: flex;
        flex-grow: 1;
        align-items: center;
        min-height: 130px;
      }

      > a {
        padding: 12px 15%;
        background-color: black;
        color: white;
        align-self: center;
        border-radius: 25px;
      }

      > div {
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          border-radius: 5px;
          font-size: 0.85em;
          margin: 5px 0 0 0;
          padding: 10px;

          &:not(:empty) {
            background-color: #d9d9d9;
          }
        }
      }

      img {
        padding: 20px 30px;
        box-sizing: border-box;
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: min-content;
      }

      > div:first-child {
        height: 110px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
