@import 'scss/env.scss';

.main {
  padding: 0 2.5%;
  margin: 0 0 20px 0;

  > h2 {
    text-align: center;
  }

  > form {
    background-color: $theme-color-overlay;
    width: auto;

    input:not(input[type='checkbox']) {
      width: 50%;
    }

    > div {
      > div:last-child {
        background-color: $theme-color-overlay;
      }
    }
  }
}
