@use 'sass:color';
@import 'scss/env.scss';
@import 'scss/components/_button.module.scss';

body {
  @include pm();
  overflow-x: hidden;
  background-color: $theme-color-background;
}

* {
  &::-webkit-scrollbar {
    padding: 2px;
    box-sizing: border-box;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    box-shadow: inset 0 0 3px $theme-color-overlay;
    border-radius: 5px;
  }
}

#root {
  position: relative;
  z-index: 1;
  @include flex($dir: column);
  min-height: 100vh;
}

header {
  @include flex($dir: column, $justify: space-between, $align: center, $wrap: wrap);
  color: black;
  align-items: stretch;

  box-sizing: border-box;
  margin-bottom: 5px;

  > div {
    display: flex;

    &:first-child {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 5px;

      > div:nth-child(2) {
        flex-grow: 1;
      }

      > div,
      > a {
        display: flex;
        margin: 5px;
        p {
          text-align: center;
        }

        > div {
          display: flex;
          align-items: center;

          > button {
            @extend .button-primary;
            font-size: 1em;
            margin: 10px;
          }
        }

        &:last-child {
          flex-direction: column;
        }
      }
    }

    &:last-child {
      flex-wrap: wrap;
    }
  }

  button,
  a {
    box-shadow: none;
  }

  img {
    height: 80px;
    transition: 0.3s;
  }
}

main {
  @include flex($dir: column, $justify: center, $align: stretch);
  flex-grow: 1;
}

footer {
  @include flex($dir: column, $align: center);
  background-color: black;
  z-index: 1;
  > div {
    color: white;
    font-size: 1.5em;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    @include flex($justify: space-evenly, $wrap: wrap);
    text-shadow: 0 0 3px rgba(black, $theme-color-transparency);

    > div {
      @include flex($justify: center);
      flex: 1 1 0;
      > a {
        margin: 10px;
        color: $color-inverse;
        box-shadow: none;
      }
    }
  }
  > a {
    @include flex($align: flex-end);
    padding: 5px;
    box-shadow: none;
    width: fit-content;

    > * {
      margin: 0 10px;
    }

    > p {
      color: #13a582;
      font-family: Montserrat;
      font-weight: 900;
      white-space: nowrap;
      transform: translateY(3px);
    }

    > img {
      width: 150px;
    }
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
button {
  transition: $default-transition-time;
  box-shadow: 0px 0px 4px 1px $theme-color-overlay;

  &:hover {
    filter: $theme-hover-filter;
  }
}

a {
  text-decoration: none;
  display: block;
}

button {
  border: none;
  @extend .button-default;
}

hr {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: Montserrat;
  font-weight: 900;
}

* {
  font-family: Montserrat;
}

h1 {
  font-size: 2.4em;
  font-family: Montserrat;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0;
}

h2 {
  font-size: $font-size-normal;
  font-family: Montserrat;
  font-weight: lighter;
  text-transform: uppercase;
  margin: 30px 0;
}

h3 {
  font-family: Montserrat;
}

div > label,
label + div {
  padding: $small-padding;
}

input {
  border: none;
  outline: none;
  background-color: $theme-color-overlay;
  padding: $small-padding;
  color: #111;
  transition: 0.3s;
  &:disabled {
    color: color.adjust($theme-color, $saturation: -50%);
    background-color: color.adjust($theme-color-overlay, $alpha: 0.1);
  }

  &[type='checkbox'] {
    border-radius: 0;
    border: 2px solid black;
    appearance: none;
    cursor: pointer;
    height: 25px;
    width: 25px;
    opacity: 1;
    &:checked {
      position: relative;
      background-color: black;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1000;
        transform-origin: bottom left;
        clip-path: polygon(14% 50%, 6% 65%, 50% 95%, 95% 16%, 80% 8%, 43% 62%);
      }
    }
    &:disabled {
      opacity: 0.4;
    }
  }
}

form {
  @include flex($dir: column);
  @include pm($padding: 20px);
  background-color: white;
  border-radius: 5px;
  color: black;
  min-width: 300px;
  width: 60%;

  > h2 {
    text-align: center;
  }

  > div {
    @include flex($justify: space-between, $align: center);
    margin: $normal-margin;

    > * {
      font-size: $font-size-default;
    }
    > label {
      font-family: Montserrat;
    }
    > *:last-child {
      &:not(select) {
        padding: $small-padding;
      }
      &:is(input):disabled {
        color: #888;
      }
    }

    input {
      border-radius: 5px;
    }
  }
  > button,
  > div > button:last-child {
    margin: 20px;
    align-self: flex-end;
  }
}

select {
  height: 40px;
}

hr {
  background-color: $theme-color-inside;
  border: none;
  margin: $normal-margin;
}

#toast-container {
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiButtonBase-root,
.PrivatePickersYear-root,
.css-m1gykc {
  box-shadow: none;
  padding: 0;
}
