.main {
  display: flex;
  align-items: center;
  color: black;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;

  > * {
    margin: 5px;
    padding: 0;
  }

  &:hover {
    background-color: black;
    color: white;

    * {
      color: white;
    }
  }
}
