.main {
  display: flex;
  justify-content: space-between;
  height: 35px;

  * {
    font-size: 1.3em;
  }

  span {
    width: 100%;
  }

  input {
    width: 100%;
  }

  > div {
    display: flex;
    align-items: center;
    margin: 5px;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
