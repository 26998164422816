.main {
  position: relative;
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px 4%;
  flex-grow: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .display {
      display: flex;
      align-items: center;

      * {
        font-family: Arial, Helvetica, sans-serif;
      }

      > div {
        margin: 10px;
      }

      .view {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;

        > button {
          box-shadow: none;
          border-radius: 30px;
          filter: none;
          margin: 4px 10px;
          padding: 4px 10px;
          font-size: 0.9em;
          background-color: transparent;
          color: black;
          &:hover {
            background-color: #0002;
          }
        }

        .selected {
          background-color: #000;
          color: white;
        }
      }
    }
  }
}

.bar {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 10%;
  min-width: 230px;
  padding: 5px;
  box-sizing: border-box;
  max-height: 100vh;
  z-index: 1000;

  &:first-child {
    z-index: 1000;
    overflow: auto;
  }

  &:last-child {
    z-index: -1;
  }

  @media (max-width: 700px) {
    min-width: 0%;
  }

  > div {
    padding: 2px;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-wrap: wrap;

      &:first-child {
        padding: 10px 0;
        box-sizing: border-box;
      }
    }
  }

  .activeFilters {
    display: flex;
    flex-wrap: wrap;

    > div {
      padding-left: 10px;
      box-sizing: border-box;
      width: 100%;

      &:first-child {
        position: relative;
        padding: 10px 10px 15px 20px;
        margin: 0;
        text-transform: uppercase;

        &::before {
          position: absolute;
          left: -10px;
          top: -9%;
          content: '';
          width: 16px;
          height: 100%;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
          box-shadow: 0 0 10px 3px #00d8f4d0;
          background-color: #00d8f4;
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          label {
            text-transform: uppercase;
            padding: 2px;
            box-sizing: border-box;
            font-size: 0.8em;
            word-break: break-all;
            text-align: right;
          }

          input {
            width: 20px;
            height: 20px;
          }

          svg {
            min-width: 20px;
            min-height: 20px;
            padding: 3px;
            margin-left: 3px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: white;
              background-color: #000;
            }
          }
        }

        > * {
          margin: 2px;
        }
      }
    }

    .visualSearch {
      display: flex;
      > div {
        max-height: 140px;
        max-width: 140px;
        height: 140px;
        border: 4px solid #59d5f3;
        border-radius: 5px;
      }
      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        box-shadow: none;
        color: #59d5f3;
        padding: 0;
        margin: 2px;
        border-radius: 50%;
        background-color: #0001;

        &:hover {
          filter: brightness(95%);
        }
      }
    }
  }

  hr {
    min-height: 1px;
    margin: 1px 0;
    background-color: black;
  }

  .filters {
    hr {
      background-color: #bbb3;
    }

    > div {
      flex-direction: column;
      text-transform: uppercase;

      > div {
        display: flex;
        flex-direction: column;
        width: 100%;

        > div {
          display: flex;
        }
      }

      select {
        width: 100%;
      }
    }
  }

  label {
    font-size: 0.85rem;
    padding: 3px;

    & + div {
      padding: 0px;
    }
  }

  button {
    font-size: 0.9em;
  }

  * {
    font-family: Montserrat;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &Element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 3px;
    font-size: 1em;

    > div,
    > label {
      &:last-child {
        font-size: 1.4em;
      }
    }

    &Book {
      display: flex;
      align-items: center;
      > svg {
        margin: 0 5px;
      }
    }
  }

  &Content {
    display: flex;
    flex-wrap: wrap;
  }

  &Books {
    flex-direction: column;
    > div {
      display: flex;
      align-items: center;
      margin: 1px;
      width: 100%;

      > div:last-child {
        margin-left: 5px;
        word-break: break-all;
        word-wrap: break-word;
      }

      &:not(:first-child) {
        label {
          position: relative;

          .hover {
            position: absolute;
            top: 0;
            left: 0;
            background-color: white;
            border-radius: 5px;
            z-index: 1000000;
          }
        }
      }
    }
  }

  &Pattern {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      margin: 3px;

      > *:last-child {
        margin-left: 10px;
      }
    }
  }

  &Drawing {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
    }
  }

  &Color {
    display: flex;
    flex-wrap: wrap;
    > div {
      > input[type='checkbox'] {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border: none;
      }
    }
  }

  &Dates {
    flex-direction: column;
    margin: 2px 0;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  input[type='checkbox'] {
    $s: 16px;
    min-width: $s;
    min-height: $s;
    max-width: $s;
    max-height: $s;
  }
}

.dateInput {
  max-width: 120px;
  flex-grow: 1;
}

.popup {
  z-index: 5;
}

.showPlus {
  display: flex;
  justify-content: center;
  margin: 5px;
  > button {
    padding: 5px 20px;
  }
}

.searchBook {
  width: 100%;
}
