@import 'scss/env.scss';

.main {
  > div {
    &:first-child {
      display: flex;
    }
    &:last-child {
      transition: $default-transition-time;
      overflow: hidden;
    }
  }
}

.box {
  height: fit-content;
  width: 100%;
}
