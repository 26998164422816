.main {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  > div {
    display: flex;
    margin: 0 3%;
    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 5px;
      margin: 5px 1%;
      box-sizing: border-box;
      height: 100%;
    }

    &:last-child {
      justify-content: center;
    }
  }

  .left,
  .right {
    > div {
      margin: 5px;
    }
    max-width: 600px;
  }

  .left {
    width: 40%;

    .page {
      display: flex;
      flex-grow: 1;
      align-items: center;
      max-height: 75vh;
      height: 75vh;

      &Share {
        display: flex;

        > div {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          border: 2px solid #000;
          margin: 5px;

          > div {
            display: flex;
            align-items: center;
            margin: 2px 5px;
            padding: 1px 5px;

            &:hover {
              background-color: black;
            }

            &:first-child {
              display: block;
              text-align: center;
            }

            > div {
              display: flex;
              padding: 5px;
            }
          }
        }
      }

      &Arr {
        width: 40px;
        button {
          position: relative;
          background-color: transparent;
          padding: 0;
          box-sizing: border-box;
          aspect-ratio: 1/1;
          font-size: 1.5em;
          width: 100%;
          box-shadow: none;
          color: black;
        }
      }

      &Img {
        display: flex;
        align-items: stretch;
        flex-grow: 1;
        height: 100%;
        padding: 0;
        background-color: #eee;
        border-radius: 4px;

        > * {
          flex-grow: 1;
          height: auto;
        }

        img {
          height: 100%;
        }
      }

      > div {
        padding: 5px;
        box-sizing: border-box;
      }

      &Num {
        display: flex;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 1;

          > * {
            margin: 5px;
            padding: 5px;
          }
        }

        &Field {
          width: 60px;
        }
      }
    }
  }

  hr {
    height: 4px;
    background-color: black;
    border-radius: 10px;
    box-sizing: border-box;
    flex-grow: 1;
    width: auto;
    box-sizing: border-box;
  }

  .right {
    width: 600px;

    > hr {
      flex-grow: unset;
    }

    .patterns {
      display: flex;
      padding: 5px 20px;
      margin: 5px 5px 10px 5px;
      overflow: auto;
      flex-wrap: wrap;
      height: 530px;
      align-content: flex-start;
      z-index: 0;

      > div {
        width: 33%;
        aspect-ratio: 1/1;
        height: auto;
      }
    }

    .share {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 10px;

      &Button {
        margin: 5px;
        padding: 5px 10px;
      }

      > button {
        display: flex;
        padding: 0 10px;
      }
    }

    > div {
      display: flex;
    }
  }

  .name {
    position: relative;
    align-items: center;
    z-index: 2;
    * {
      box-shadow: none;
    }

    a {
      padding: 0 5px;
    }
  }
}

.book {
  background-color: transparent;
  padding: 10px;
  margin: 0;
  box-shadow: none;
  color: black;
  transform: rotate(180deg);
  font-weight: bold;
}

.form {
  position: absolute;
  transition: 0.2s;
  top: 100%;
  display: flex;
  background-color: white;
  border: 2px solid #000;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px;
    border-radius: 4px;

    &:hover {
      background-color: #0001;
    }
  }

  form {
    position: relative;
    padding: 10px;
    flex-grow: 1;
    width: auto;

    * {
      font-size: 1em;
    }

    > div {
      margin: 5px;
    }

    label {
      width: 40%;
    }
  }

  .sub {
    align-items: unset;

    > div:last-child {
      margin: 0 0 0 30px;
      padding-right: 0;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px;
      }
    }
  }

  > div {
    width: 100%;
  }

  button {
    margin: 5px;
  }

  .edit {
    display: flex;
    background-color: transparent;
    color: #000;
    padding: 5px;
    box-shadow: none;
    height: auto;
    flex-grow: 0;
    justify-self: flex-start;
    align-self: flex-start;
  }

  .other {
    width: 45%;

    > div {
      padding: 5px;
    }
  }
}

.edit {
  cursor: pointer;
  aspect-ratio: 1/1;
}

.popper {
  display: flex;
  flex-direction: column;
  width: 400px;

  > div {
    text-align: center;
    font-weight: bold;
    margin: 5px;
  }

  * {
    font-size: 1em;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > div {
      text-align: left;
      margin: 5px 0 0 10px;
    }
  }

  button {
    display: flex;
    margin: 5px;
    padding: 5px;

    > * {
      margin: 2px 10px;
    }
  }
}
