@import 'scss/env.scss';

.main {
  display: inline-block;
  background-color: rgb(95, 0, 0);
  border-radius: $default-border-radius;
  border: 4px solid red !important;
  color: red;
  @include pm($padding: $normal-padding);
  transition: $default-transition-time;
  justify-self: center;

  > div {
    font-family: Arial;
  }

  > hr {
    margin: $normal-margin 0;
    width: 70%;
    height: 2px;
    background-color: red;
  }
}
