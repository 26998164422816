.main {
  position: absolute;
  width: 100%;
  height: 100%;
  > div {
    position: relative;
    background-color: red;
    pointer-events: none;
    overflow: hidden;

    > img {
      position: absolute;
      min-width: 120%;
      min-height: 120%;
      max-height: 180%;
      max-width: 180%;
      width: auto;
      height: auto;
    }
  }
}
