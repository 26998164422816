@import 'scss/_env.scss';

.main {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: $theme-color-background;
  transition: 1s;

  img {
    position: relative;
    transition: 3s;
    animation: 1s ease transition forwards;
    width: 40%;
    height: 40%;
  }
}

@keyframes transition {
  0% {
    top: -50px;
    opacity: 0;
    filter: drop-shadow(0 0 0px #222);
  }
  100% {
    top: 0px;
    opacity: 1;
    filter: drop-shadow(0 0 10px #222);
  }
} ;
