.account {
  position: relative;
  display: flex;
  flex-direction: row;
  background: linear-gradient(275.15deg, rgba(54, 216, 255, 0.45) 36.9%, rgba(205, 255, 166, 0.45) 71.81%, rgba(252, 255, 123, 0) 119.76%);
  box-shadow: 3px 5px 19px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 55%;
  overflow: hidden;
  z-index: 1;

  .images {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    margin: 0;

    > img {
      position: absolute;
      bottom: 0;
      width: auto;
      padding: 0;

      &:first-child {
        height: 80%;
        aspect-ratio: 1/1;
        left: 0;
      }

      &:last-child {
        right: 0;
        width: 50%;
        height: 100%;
        text-align: right;
        object-position: bottom right;
      }
    }
  }

  * {
    color: black;
    font-family: Montserrat;
  }

  .title {
    display: flex;
    font-weight: bold;
    font-size: 1.9em;
    text-align: center;
    margin: 20px 5%;
    width: 50%;
    flex-grow: 1;
  }

  input {
    background-color: white;
    border-radius: 4px;
    padding: 13px 23px;
    margin: 5px;
    width: 100%;
    font-size: 1rem;

    &::placeholder {
      opacity: 0.3;
    }
  }

  button {
    background-color: black;
    color: white;
    font-size: 1rem;
    border-radius: 20px;
    padding: 11px 20px;
  }

  .connect {
    display: flex;
    width: 40%;
    min-width: 300px;
    flex-direction: column;
    align-items: stretch;

    > div {
      display: flex;
      margin: 10px;
    }

    > a {
      font-size: 0.6rem;
      color: black;
      font-weight: bold;
    }

    button {
      width: 100%;
    }

    .connections {
      display: flex;
      flex-direction: column;
      width: auto;
      height: auto;

      .or {
        display: flex;
        align-items: center;

        font-size: 0.5em;
        margin: 10px;

        hr {
          background-color: black;
          height: 1px;
        }
      }
    }
  }
}
