@import 'scss/env.scss';

.main {
  @include flex($justify: center, $align: center);
  @include pm($padding: $normal-padding);

  > form {
    align-self: center;
  }

  a {
    margin: 0;
    padding: 0;
    background-color: transparent;
    text-align: right;
    font-style: italic;
    color: white;
    box-shadow: None;
  }

  &Buttons {
    > button {
      width: auto !important;
      height: auto !important;
      box-sizing: border-box;
      font-size: 1.4em;
      padding: 10px 20px !important;
    }
  }
}
