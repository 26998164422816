@import 'scss/env.scss';

.main {
  h1,
  * {
    font-family: Montserrat;
    font-weight: normal;
  }

  .filtering {
    display: flex;
    justify-content: center;
    min-width: 300px;
    background-color: $theme-color-overlay;
    padding: 10px;
    border-radius: $default-border-radius;
    * {
      font-weight: bold;
    }
  }

  &Header {
    display: flex;
    justify-content: space-evenly;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        margin: 5px;
      }

      > div {
        display: flex;
        button {
          font-size: 0.8em;
          color: white;
          font-weight: bold;
          border-radius: 0;
          $rad: 20px;
          &:first-child {
            border-bottom-left-radius: $rad;
            border-top-left-radius: $rad;
          }
          &:last-child {
            border-bottom-right-radius: $rad;
            border-top-right-radius: $rad;
          }
        }
      }
    }

    &Active {
      background-color: $theme-color-secondary !important;
      text-shadow: 0 0 1px black;
    }
  }

  &Content {
    margin: 20px 10px;
  }

  .patterns {
    @include flex($justify: space-evenly, $wrap: wrap);
    position: relative;
    user-select: none;

    &InBook {
      display: flex;
      border-radius: $default-border-radius;
      transform-style: preserve-3d;
      height: 100%;

      $size: 20%;

      > div {
        @include flex($dir: column, $align: center);
        transform-style: preserve-3d;

        > div {
          transform-style: preserve-3d;

          &:first-child {
            min-height: 5%;
          }
          &:last-child {
            @include flex($wrap: wrap);
          }
        }
      }

      &Item {
        @include flex($dir: column);
        cursor: pointer;
        margin: 2.5%;
        width: $size;
        background-color: #1d1d1d83;
        transform-style: preserve-3d;
        user-select: none;

        &:hover {
          box-shadow: 0 0 3px #1d1d1d83;
          > div {
            transform: translate3d(-2px, -2px, 0);
          }
        }

        > div {
          transition: transform $default-transition-time;
          box-sizing: border-box;
          overflow: hidden;
          transform-style: preserve-3d;

          img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
          }

          &:last-child {
            @include flex($align: center);
            flex-grow: 1;

            > p {
              color: black;
              font-family: NixieOne;
              font-weight: bold;
              text-align: center;
              align-self: center;
            }
          }
        }

        &Placeholder {
          height: auto;
          border-radius: $default-border-radius;
        }
      }
    }
  }

  .pattern {
    @include flex($dir: column);
    margin: $normal-margin;
    width: $default-image-size;
    background-color: $theme-color-overlay;

    > div {
      padding: 5px;
      box-sizing: border-box;
      overflow: hidden;

      img {
        border-radius: 5px;
        width: 100%;
        height: $default-image-size;
        object-fit: cover;
      }

      &:last-child {
        @include flex($align: center);
        flex-grow: 1;

        > p {
          color: black;
          font-family: NixieOne;
          font-weight: bold;
          text-align: center;
          align-self: center;
        }
      }
    }
  }

  .book {
    padding: 4% 15%;
    box-shadow: inset 0 0 50px #000, 0 0 10px 5px #000;
    background-color: rgb(59, 56, 51);
  }

  .placeholder {
    width: $small-padding * 2 + $default-image-size;
    aspect-ratio: 7/9;
    height: auto;
    margin: $normal-margin;
  }
}
