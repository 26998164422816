@import 'scss/env.scss';

.main {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $theme-color-box;
  border-radius: $default-border-radius;
  min-width: 500px;
  width: 40%;
  box-shadow: 0 0 5px 1px $theme-color-primary-overlay;

  > div {
    display: flex;

    div {
      font-family: Montserrat;
      margin: 5px;
    }
  }

  &Keys {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 40%;

    > div {
      text-shadow: 0 0 1px $theme-color-quaternary;
      font-weight: 900;
      margin: 5px;
    }

    &Concat {
      height: fit-content;
      padding: 5px;
    }

    &Key {
      padding: 10px;
      border-bottom: 3px solid $theme-color-primary-overlay;
    }
  }

  &Keys > div,
  &Description {
    background-color: $theme-box-color;
    border-radius: $default-border-radius;
  }

  &Description {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 5px;
    flex-grow: 1;
  }
}
