@import 'scss/env';

.main {
  display: block;
  width: 500px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: $theme-box-shadow;
}
