@import 'scss/env.scss';

.main {
  padding: 0 5%;

  &Selected {
    svg {
      color: $theme-color-secondary !important;
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }
}

.search {
  display: flex;
  flex-wrap: wrap;

  .visual {
    margin: 0 10px;
    text-align: center;
    background-color: transparent;
    color: black;
    font-size: 1.1rem;
  }

  .visual {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    &:hover {
      filter: none;
    }
    img {
      height: 100%;
      aspect-ratio: 1/1;
      width: auto;
    }

    &:hover {
      > div {
        opacity: 1;
      }
    }

    > div {
      position: absolute;
      bottom: 0;
      left: 50%;

      min-width: 135px;
      transform: translate(-50%, calc(100% + 10px));
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;

      > div {
        &:first-child {
          background-color: white;
          font-weight: bold;
          border-radius: 4px;
          font-size: 0.55rem;
          padding: 10px;
          z-index: 1;
          border: 2px solid #59d5f3;
        }
        &:last-child {
          position: absolute;
          top: 0;
          left: 50%;
          height: 40px;
          width: 40px;
          background-color: #59d5f3;
          transform: translate(-50%) rotate(45deg);
          border: 2px solid #59d5f3;
          border-radius: 4px;
          opacity: 1;
          pointer-events: none;
          z-index: -1;
        }
      }
    }
  }
}

.nav {
  > div {
    > a,
    > div {
      margin: 5px;
      color: black;

      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
}

.popup {
  background: none;
  background-color: #0004;
}

.lang {
  position: relative;
}
