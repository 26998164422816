@import 'scss/utils/variables.scss';
@import 'scss/theme.scss';

.button-default {
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.2em;
  padding: $normal-padding $big-padding;
  background-color: black;
  font-family: Montserrat;
  transition: 0.3s;
  color: white;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:hover {
    filter: brightness(80%);
  }
}

.button-inverse {
  @extend .button-default;
  color: $color-inverse;
}

.button-valid {
  @extend .button-inverse;
  background-color: $theme-color-valid;
  &:hover {
    background-color: rgba($color: $theme-color-valid, $alpha: $default-background-hover-opacity);
  }
}

.button-invalid {
  @extend .button-inverse;
  background-color: $theme-color-invalid;
  &:hover {
    background-color: rgba($color: $theme-color-invalid, $alpha: $default-background-hover-opacity);
  }
}

.button-ignore {
  @extend .button-inverse;
  background-color: $theme-color-ignore;
  &:hover {
    background-color: rgba($color: $theme-color-ignore, $alpha: $default-background-hover-opacity);
  }
}

.button-validate {
  @extend .button-default;
  background-color: $theme-color-button;
  color: white;
  font-family: Montserrat;
}

.button-primary {
  @extend .button-default;
  color: white;
  background-color: black;
  text-transform: uppercase;
}

.button-selected {
  @extend .button-default;
  background-color: $theme-color-secondary;
  color: white;
  text-transform: uppercase;
}
