.main {
  margin: 0 8%;

  .header {
    display: flex;
    margin: 5px;
    height: 50px;
    justify-content: space-between;
    margin-bottom: 20px;

    input {
      padding: 10px;
      border-radius: 4px;
    }

    .show {
      width: 50px;
    }

    .add {
      display: flex;
      align-items: center;
      width: 300px;
      justify-content: center;

      > button {
        padding: 2px;
        margin: 0px;
        flex-grow: 1;
      }

      > form {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        min-width: auto;
        flex-grow: 1;

        button {
          margin: 0;
          align-self: auto;
          font-size: 1em;
          padding: 5px 10px;
        }

        input {
          background-color: #ddd;
          width: 150px;
          margin: 5px 10px;
          flex-grow: 1;
        }

        > * {
          margin: 2px;
        }
      }

      > * {
        margin: 5px;
      }
    }

    > div {
      display: flex;
      align-items: center;

      > * {
        margin: 0 5px;
      }
    }
  }
}

.item {
  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 2px solid black;

    .arrow {
      font-size: 2em;
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }

    .actions {
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        aspect-ratio: 1/1;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
        color: black;
        font-weight: bold;
        align-self: unset;
        margin: 0 5px;

        &:not(:last-child) {
          box-shadow: none;
          font-size: 1.4em;
          &:hover {
            background-color: transparent;
            filter: none;
          }
        }

        &:last-child {
          border: 2px solid black;
          font-size: 1em;
        }
      }
    }

    > * {
      display: flex;
      align-items: center;
      > * {
        margin: 5px;
      }
    }
  }

  .dropdown {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;

    > .selection {
      position: relative;
      display: flex;
      margin: 10px;
      padding: 5px;
      box-shadow: 2px 2px 2px 3px #0005;
      border-radius: 4px;
      width: 220px;

      .card {
        display: flex;
        flex-direction: column;
        width: 100%;

        > div {
          margin: 5px;
          word-break: break-all;
        }
      }

      .cross {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 20px;
        aspect-ratio: 1/1;
        background-color: white;
        border-radius: 50%;
        border: 2px solid black;
        transform: translate(50%, -50%);
        cursor: pointer;
        user-select: none;
      }
    }

    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      width: 100%;
      border-radius: 4px;
      user-select: none;
    }
  }

  form {
    width: auto;
    min-width: auto;
    padding: 0;
  }
}
