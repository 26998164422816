@import 'scss/env';

.main {
  padding: 0 2.5%;
  > div {
    display: flex;
    background-color: $theme-color-overlay;
    border-radius: $default-border-radius;
    padding: 5px;

    > div {
      &:first-child {
        min-width: $default-big-image-size;
        height: $default-big-image-size;
        margin: 10px;
        border-radius: 5px;
        overflow: hidden;

        img {
          object-fit: cover;
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px;

        > div {
          display: flex;
          > h3 {
            word-wrap: break-word;
            margin: 5px 0;

            &:first-child {
              min-width: 64px;
            }
          }
        }
      }
    }
  }
}
