.main {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  > div {
    font-family: Montserrat;
    font-weight: bold;
  }
}

.crop {
  position: relative;
  height: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  > * {
    margin: 10px;
    max-width: 600px;
    max-height: 800px;

    img {
      user-select: none;
      height: 100%;
      width: 100% !important;
      max-height: 50vh;
    }
  }

  .image {
    height: 100%;
    padding: 10px;
    background-color: #e6f9fd;
    box-sizing: border-box;
    min-width: 300px;
    border-radius: 5px;
    > * {
      width: 100%;
      height: 100%;
    }
  }

  .preview {
    border-radius: 5px;
    overflow: hidden;

    > canvas {
      width: 200px;
      height: 200px;
      object-fit: fill;
    }
  }

  > button {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    color: red;
    font-weight: bold;
    background-color: #0001;
  }
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  background-color: #0001;
  color: black;
  border-radius: 5px;

  > * {
    margin: 0 5px;
  }

  > div {
    display: flex;
    align-items: center;
    font-size: 0.8em;
  }
}
