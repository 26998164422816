@import 'scss/env.scss';

.main {
  padding: 0 2.5%;

  hr {
    height: 1px;
    background-color: black;
    margin: 30px 0 0 0;
  }

  > div {
    > .patterns,
    > .counterfeits {
      @include flex();
      background-color: $theme-color-overlay;
      padding: 5px;
      border-radius: 5px;

      > div {
        &:first-child {
          @include flex($justify: space-evenly);
          margin: auto;
          flex-grow: 1;
          overflow-x: hidden;
          padding: 0 $normal-padding 0 0;

          > a {
            margin: $normal-margin;
            box-sizing: border-box;
            padding: 5px;
            border-radius: $default-border-radius;
            background-color: $background-color;

            &:hover {
              filter: brightness(80%);
            }

            > img {
              @include size(200px);
              object-fit: cover;
            }

            > div {
              @include flex($dir: column, $align: center);
              @include size($w: 200px);
              margin: $small-margin 0;
              padding: $small-padding;
              box-sizing: border-box;
              font-family: Montserrat;
              font-size: $font-size-default;
              color: $color;

              > p {
                margin: 4px 0;
                box-sizing: border-box;
                word-break: break-all;
                width: 100%;
                overflow-x: hidden;
                font-size: 0.8em;
                text-align: center;
              }
            }
          }
        }

        &:last-child {
          @include flex($justify: space-evenly, $align: center);
          padding: 0 0 0 40px;

          > a {
            @extend .button-default;
          }
        }
      }
    }

    > .stats {
      @include flex($justify: space-between, $align: center);
    }

    > .table {
      @include flex($dir: column);
      background-color: $theme-color-overlay;
      padding: 5px;
      border-radius: 5px;

      > div {
        @include flex($justify: space-between);
        @include pm($padding: $normal-padding);
        border-radius: 5px;
        margin: 2px 0;
      }

      > div:nth-child(2n) {
        background-color: $theme-color-overlay;
      }

      > div:nth-child(2n + 1) {
        background-color: $grid-color-secondary;
      }

      p {
        font-family: Montserrat;
        font-size: $font-size-default;
      }
    }

    > div:last-child {
      @include flex($justify: flex-end);
      margin: $large-margin $normal-margin $normal-margin $normal-margin;

      > button {
        @extend .button-validate;
      }
    }

    .manage {
      margin-right: 2%;
    }
  }
}
