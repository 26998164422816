@import 'scss/env.scss';

.main {
  transition: left $default-transition-time, top $default-transition-time, opacity $default-transition-time;
  background: linear-gradient(to bottom right, rgba($color: black, $alpha: 0.3), rgba($color: black, $alpha: 0.6), rgba($color: black, $alpha: 0.3));
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  cursor: default;

  display: flex;
  justify-content: center;
  align-items: center;

  &Up {
    pointer-events: all;
  }

  &Down {
    pointer-events: none;
  }
}
