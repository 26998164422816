@font-face {
  font-family: 'Philosopher';
  src: local('Philosopher'), url('../fonts/Philosopher/Philosopher-Regular.ttf');
}

@font-face {
  font-family: 'Philosopher';
  font-weight: bold;
  src: local('Philosopher'), url('../fonts/Philosopher/Philosopher-Bold.ttf');
}

@font-face {
  font-family: 'NixieOne';
  src: local('NixieOne'), url('../fonts/Nixie_One/NixieOne-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-BoldItalic.otf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
