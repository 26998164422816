.main {
  > .header {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
  }

  .ariane {
    align-self: flex-start;
  }
}
